<div class="questionlist">
  <div class="questionlist-outer" class:bottom-fade={scrollLeft > 15}>
    <div class="questionlist-inner" on:scroll={onInnerScroll} bind:this={scrollDiv}>
      {#each videos as video, index}
        <QuestionListItem video={video} index={index} bind:el={questionDivs[index]} />
      {/each}
    </div>
  </div>
  <div class="bottom">
    <a class="tmrrw" href="https://tmrrw.nl" target="_blank" on:click={() => pushEvent('click_tmrrw_logo', {})}>
      Powered by <span class="logo"><TmrrwLogo style="height: 0.7em; fill:black" /></span>
    </a>
  </div>
</div>

<script lang="ts">
import type { Video } from "../models/Video";
import { currentVideoIndex } from "../stores/videos";
import QuestionListItem from './QuestionListItem.svelte';
import { onMount } from "svelte";
import TmrrwLogo from "../TmrrwLogo.svelte";
import { pushEvent } from "../stores/analytics";

export let videos: Video[];
let scrollDiv: HTMLElement;
let questionDivs: any[] = [];
let scrollLeft = 0;
let currentScroll = 0;

onMount(() => {
  onInnerScroll();
});

function onInnerScroll() {
  const scrollRange = scrollDiv.scrollHeight - scrollDiv.offsetHeight;
  currentScroll = scrollDiv.scrollTop;
  scrollLeft = scrollRange - currentScroll;
}

currentVideoIndex.subscribe(idx => {
  if(!scrollDiv) { 
    return;
  }
  const middle = scrollDiv.offsetHeight / 2;
  scrollDiv.scrollTop = questionDivs[idx].offsetTop - middle + questionDivs[idx].offsetHeight / 2;
})
</script>
  
  <style lang="scss">
    .questionlist {
      color: black;
      display: flex;
      flex-direction: column;
      justify-content: start;
      height: 100%;
    }

    .questionlist-outer {
      position: relative;
      overflow: hidden;

      &:before, &:after {
        display: block;
        content: '';
        position: absolute;        
        left: 0;
        right: 0;
        height: 100px;
        pointer-events: none;
        opacity: 0;
        will-change: opacity;
        transition: opacity 0.2s;
      }
      
      &:before {
        top: -2px;
        background: linear-gradient( rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
      }

      &:after {
        bottom: -2px;
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
      }

      &.bottom-fade {
        &:after {
          opacity: 1;
        }
      }
    }

    .questionlist-inner {
      overflow: scroll;
      height: 100%;
    }

    .bottom {
      padding: 1.2em;
      display: flex;
      flex-direction: row;
    }

    .tmrrw {
        text-decoration: none;
        display: block;
        color: #777777;
        font-size: 1.1em;
        pointer-events: all;
        &:hover {
            color: #333333;
        }
    }
  </style>