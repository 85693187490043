<script lang="ts">
  import { onMount } from "svelte";
  import type { Video } from "../models/Video";
  import {
    currentVideo,
    currentVideoIndex,
    currentVideoProgress,
    gotoNextVideo,
    firstPlay,
  } from "../stores/videos";
  import { pushEvent } from "../stores/analytics";
  import { getObjectPositionForFace } from "../utils";
  import { useFaceDetection } from "../stores/debug";
  import PlayButton from "./PlayButton.svelte";
  import { writable } from "svelte/store";
  import { globalPaused } from "../stores/player";
  import mux from "mux-embed";

  let videoEl: HTMLVideoElement;
  export let video: Video;
  export let index: number;
  let interval: number;
  let currenvtVideo_unsub;
  let loaded = false;
  const paused = writable(true);

  paused.subscribe((pauseValue) => {
    if ($currentVideo.id === video.id) {
      globalPaused.set(pauseValue);
    }
  });

  onMount(async () => {
    currentVideoIndex.subscribe((currentVideoIndex) => {
      if (Math.abs(index - currentVideoIndex) <= 1) {
        onActivate();
      } else {
        onDeactivate();
      }
    });
  });
  function correctForFacePosition() {
    if ($useFaceDetection) {
      const positionYVideo = getObjectPositionForFace(
        {
          originalWidth: videoEl.videoWidth,
          originalHeight: videoEl.videoHeight,
          currentWidth: videoEl.offsetWidth,
          currentHeight: videoEl.offsetHeight,
        },
        video.details?.faces[0]?.midpoint?.[0]?.y || null
      );

      videoEl.style.objectPosition = `50% ${positionYVideo}px`;
    } else {
      videoEl.style.objectPosition = `50% 50%`;
    }
  }

  async function onActivate() {
    if (loaded) {
      return;
    }

    // Mux monitor options
    let muxOptions = {
      debug: false,
      data: {
        env_key: "mvsq7ed21kql86t7gktdm02ll",

        // Player meta data
        player_name: "iFrame Player",
        player_version: process.env.BUILD_HASH,
        player_init_time: Date.now(),

        // Video Metadata
        video_id: video.id,
        video_series: video.organization_data.name || "Unknown Organisation",
        video_stream_type: "on-demand",
      },
    };

    if (videoEl.canPlayType("application/vnd.apple.mpegurl")) {
      videoEl.src = `https://player.tmrrw.nl/hls/${video.id}.m3u8`;
    } else {
      const Hls = (await import("hls.js")).default;
      let hls = new Hls({});
      if (Hls.isSupported()) {
        hls.loadSource(`https://player.tmrrw.nl/hls/${video.id}.m3u8`);
        hls.attachMedia(videoEl);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {});

        // Append HLS option for Mux Data
        muxOptions["Hls"] = Hls;
        muxOptions["hlsjs"] = hls;
      }
    }

    // Start mux monitoring
    mux.monitor(videoEl, muxOptions);

    videoEl.addEventListener("loadeddata", () => {
      // TODO: Make sure the right video ID is sent
      //pushEvent('video_loaded', {video_id: $currentVideo.id})
      loaded = true;

      // Try to force the subtitles
      if (videoEl.textTracks && videoEl.textTracks[0]) {
        videoEl.textTracks[0].mode = "disabled";
      }

      correctForFacePosition();
    });

    videoEl.addEventListener(
      "ended",
      () => {
        // If the video is in preview mode, we need to loop
        if ($firstPlay) {
          videoEl.currentTime = 0;
          videoEl.play();
        } else {
          gotoNextVideo();
        }
      },
      false
    );

    currenvtVideo_unsub = currentVideo.subscribe(async (value) => {
      if (!videoEl) {
        return;
      }

      // Play the current video
      // Else pause it so that the new one can play.
      if (value.id === video.id) {
        videoEl.currentTime = 0;
        try {
          if (!$firstPlay) {
            pushEvent("video_autoplay", {
              video_id: $currentVideo.id,
              meta: $currentVideo.meta,
            });
          }
          await videoEl.play();
        } catch (error) {
          if ($firstPlay) {
            pushEvent("video_no_autoplay", { video_id: $currentVideo.id });
          }
        }
        paused.set(false);
        startInterval();
      } else {
        videoEl.pause();
        paused.set(true);
        stopInterval();
      }
    });

    useFaceDetection.subscribe((value) => {
      correctForFacePosition();
    });
  }

  // Heavy function so be careful
  async function onDeactivate() {
    loaded = false;
    if (currenvtVideo_unsub) {
      currenvtVideo_unsub();
    }
  }

  function onVideoTap() {
    if ($firstPlay) {
      pushEvent("video_click_firstplay", {
        video_id: $currentVideo.id,
        video_question: $currentVideo.meta.question,
        meta: $currentVideo.meta,
      });
      pushEvent("video_click_play", {
        video_id: $currentVideo.id,
        video_question: $currentVideo.meta.question,
        meta: $currentVideo.meta,
      });
      $firstPlay = false;
      videoEl.currentTime = 0;
      paused.set(false);
      return;
    }

    if ($paused) {
      pushEvent("video_click_play", {
        video_id: $currentVideo.id,
        video_question: $currentVideo.meta.question,
        meta: $currentVideo.meta,
      });
      videoEl.play();
      paused.set(false);
    } else {
      pushEvent("video_click_pause", {
        video_id: $currentVideo.id,
        video_question: $currentVideo.meta.question,
        meta: $currentVideo.meta,
      });
      videoEl.pause();
      paused.set(true);
    }
  }

  function startInterval() {
    if (interval) {
      return;
    }
    interval = setInterval(() => {
      if ($currentVideo.id === video.id) {
        $currentVideoProgress = videoEl.currentTime / videoEl.duration;
      }
    }, 250);
  }

  function stopInterval() {
    if (interval) {
      clearInterval(interval);
    }
    interval = null;
  }
</script>

<div class="video" class:paused on:click={onVideoTap}>
  <video
    id={`video-${video.id}`}
    bind:this={videoEl}
    class:loaded
    playsinline
    muted={$firstPlay}
    preload="auto"
  />
  <div
    class="playbutton"
    class:hidden={!$firstPlay && (!$paused || $currentVideoIndex != index)}
  >
    <PlayButton />
  </div>
</div>

<style lang="scss">
  .video {
    background-color: black;
    position: relative;
    width: 100%;
    height: 100%;
    > video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 20%;
    }
  }

  .playbutton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &.hidden {
      display: none;
    }
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.4s;
    will-change: transition;
    &.loaded {
      opacity: 1;
    }
  }

  .hidden {
    display: none;
  }
</style>
