import { derived, get, writable } from "svelte/store";
import { defaultTheme } from "../models/theme";
import { parseParams } from "../models/URLParams";
import { collection, doc, getDoc, getDocs, getFirestore, query, where, } from "firebase/firestore";
export const firstPlay = writable(true);
export const currentPlaylist = writable(null);
export const isPlaylistLoading = writable(false);
export const currentVideoIndex = writable(0);
export const currentVideoProgress = writable(0);
export const canPlayNextVideo = writable(false);
export const canPlayPrevVideo = writable(false);
export const playlistId = writable("YtDr8Lm5IRUQGnMG683w");
export const sk123id = writable(null);
export function decidePlayListId() {
    const urlParams = new URLSearchParams(window.location.search);
    const params = parseParams(urlParams);
    if (params.pid) {
        playlistId.set(params.pid);
    }
}
export function decideSk123Id() {
    const urlParams = new URLSearchParams(window.location.search);
    const params = parseParams(urlParams);
    if (params.sk123id) {
        sk123id.set(params.sk123id);
    }
}
export async function fetchPlaylist() {
    // Try sk123id first
    const currentSk123id = get(sk123id);
    if (currentSk123id) {
        return await fetchPlaylistBySK123ID();
    }
    const pid = get(playlistId);
    if (!pid) {
        return;
    }
    isPlaylistLoading.set(true);
    const db = getFirestore();
    const playlistCollection = collection(db, "playlists");
    const snap = await getDoc(doc(playlistCollection, pid));
    const data = Object.assign({}, snap.data());
    if (!data.playertheme_data) {
        data.playertheme_data = defaultTheme;
    }
    currentPlaylist.set(data);
    currentVideoIndex.set(0);
    currentVideoProgress.set(0);
    decideCanPlay();
    isPlaylistLoading.set(false);
}
export async function fetchPlaylistBySK123ID() {
    const currentSk123id = get(sk123id);
    if (!currentSk123id) {
        return;
    }
    isPlaylistLoading.set(true);
    const db = getFirestore();
    const playlistCollection = collection(db, "playlists");
    const playlistQuery = query(playlistCollection, where("sk123id", "==", currentSk123id));
    const snaps = await getDocs(playlistQuery);
    const snap = snaps.docs[0];
    console.log(snaps.docs[0].data());
    const data = Object.assign({}, snap.data());
    if (!data.playertheme_data) {
        data.playertheme_data = defaultTheme;
    }
    currentPlaylist.set(data);
    currentVideoIndex.set(0);
    currentVideoProgress.set(0);
    decideCanPlay();
    isPlaylistLoading.set(false);
}
export async function gotoNextVideo() {
    const playlist = get(currentPlaylist);
    currentVideoIndex.update((index) => Math.min(index + 1, playlist.videos_data.length - 1));
}
export async function gotoPrevVideo() {
    currentVideoIndex.update((index) => Math.max(0, index - 1));
}
export async function gotoVideo(gotoIndex) {
    const playlist = get(currentPlaylist);
    currentVideoIndex.set(Math.max(0, Math.min(gotoIndex, playlist.videos_data.length - 1)));
}
export const currentVideo = derived(currentVideoIndex, ($currentVideoIndex) => {
    const playlist = get(currentPlaylist);
    return playlist.videos_data[$currentVideoIndex];
});
function decideCanPlay() {
    const playlist = get(currentPlaylist);
    const index = get(currentVideoIndex);
    if (playlist) {
        canPlayPrevVideo.set(index > 0);
        canPlayNextVideo.set(index < playlist.videos.length - 1);
    }
}
currentVideoIndex.subscribe(() => {
    decideCanPlay();
    currentVideoProgress.set(0);
});
