<script lang="ts">
  import { onMount } from "svelte";
  import QuestionList from "./components/QuestionList.svelte";
  import TotalVideo from "./components/TotalVideo.svelte";
  import {
    currentPlaylist,
    fetchPlaylist,
    currentVideoIndex,
    isPlaylistLoading,
    decidePlayListId,
    decideSk123Id,
  } from "./stores/videos";
  import { isDebugging, useFaceDetection } from "./stores/debug";
  import {
    windowWidth,
    windowHeight,
    playerW,
    playerH,
    totalW,
    baseSize,
    sizingState,
  } from "./stores/sizing";
  import AlwaysOverlay from "./components/AlwaysOverlay.svelte";
  import { defaultTheme } from "./models/theme";
  import { parseParams } from "./models/URLParams";

  function onKeyPress(e: KeyboardEvent) {
    if (e.key === "D" && e.shiftKey) {
      $isDebugging = !$isDebugging;
    }
    if (e.key === "F" && e.shiftKey) {
      $useFaceDetection = !$useFaceDetection;
    }
  }

  async function getPlayList() {
    await fetchPlaylist();
  }

  onMount(async () => {
    decideSk123Id();
    decidePlayListId();
    await getPlayList();

    const currentTheme = $currentPlaylist.playertheme_data ?? defaultTheme;

    // Override primary color from embed url
    const urlParams = new URLSearchParams(window.location.search);
    let params = parseParams(urlParams);
    if (params.primaryColor) {
      currentTheme.colors.primary = params.primaryColor;
    }

    for (let keyA in currentTheme) {
      if (typeof defaultTheme[keyA] === "object") {
        for (let keyB in defaultTheme[keyA]) {
          if (typeof defaultTheme[keyA][keyB] === "string") {
            try {
              window.document.body.style.setProperty(
                `--${keyA}-${keyB}`,
                $currentPlaylist.playertheme_data[keyA][keyB]
              );
            } catch (e) {
              window.document.body.style.setProperty(
                `--${keyA}-${keyB}`,
                defaultTheme[keyA][keyB]
              );
            }
          }
        }
      }
    }
  });
</script>

<svelte:window
  bind:innerWidth={$windowWidth}
  bind:innerHeight={$windowHeight}
  on:keypress={onKeyPress}
/>

<main class={`state-${$sizingState}`}>
  <div
    class="player"
    class:hidden={!$playerW}
    style="width: {$totalW}px; height: {$playerH}px; --base-size: {$baseSize}px; --player-width: {$playerW}px; --player-height: {$playerH}px;"
  >
    {#if $isPlaylistLoading === false && $totalW > 0}
      <div
        class="video-caroussel-outer"
        style="width: {$playerW}px; height: {$playerH}px;"
      >
        <div
          class="video-caroussel-inner"
          style="transform: translate3d(0, -{$currentVideoIndex *
            $playerH}px, 0)"
        >
          {#each $currentPlaylist.videos_data as video, index}
            <TotalVideo {video} {index} />
          {/each}
        </div>
        <AlwaysOverlay />
      </div>
      {#if $sizingState === "perfect" || $sizingState === "wide" || $sizingState === "too-wide"}
        <div
          class="video-list-outer"
          style="width: {$totalW - $playerW}px; height: {$playerH}px;"
        >
          <QuestionList videos={$currentPlaylist.videos_data} />
        </div>
      {/if}
    {:else}
      <div class="loading_indicator">Loading...</div>
    {/if}

    <div class="metrics" class:hidden={!$isDebugging}>
      Build: {process.env.BUILD_HASH}<br />
      Build date: {process.env.BUILD_DATE}<br />
      Total Size: {Math.round($windowWidth)} x {$windowHeight}<br />
      Player size: {Math.round($playerW)} x {$playerH}<br />
      Base size: {$baseSize}px<br />
      Sizing state: {$sizingState}<br />
      VideoIndex: {$currentVideoIndex}<br />
      Face position: {$useFaceDetection ? "On" : "Off"}<br />
      Elon Musk alive: Yes
      {#if $currentPlaylist}
        <br />VideoID: {$currentPlaylist.videos[$currentVideoIndex]}
      {/if}
    </div>
  </div>
</main>

<style lang="scss">
  @import "fonts.scss";

  :global(body),
  :global(html) {
    padding: 0;
    margin: 0;
    background-color: #fff;
    overflow: hidden;
    font-family:
      "pulp",
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      Oxygen-Sans,
      Ubuntu,
      Cantarell,
      "Helvetica Neue",
      sans-serif;
  }
  .metrics {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    padding: 1rem;
    bottom: 0;
    right: 0;
    text-align: right;
  }

  .loading_indicator {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .video-caroussel-outer {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  .video-caroussel-inner {
    position: absolute;
    width: 100%;
    display: block;
    transform: translate3d(0, 0, 0);
    transition: transform 0.44s;
    will-change: transform;
  }

  .video-list-outer {
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    background-color: white;
  }

  main {
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .player {
    position: relative;
    background-color: black;
    color: white;
    font-size: var(--base-size);
    border-radius: 0.65rem;
    overflow: hidden;
  }

  .hidden {
    display: none;
  }
</style>
