;
export function getObjectPositionForFace(dimensions, midpointYPercent) {
    const scale = dimensions.currentWidth / dimensions.originalWidth;
    const OFFSET_PERCENTAGE = midpointYPercent ? 1.15 : 1; //Offset makes sure person is not exactly in the middle but a bit higher
    const midpointY = dimensions.originalHeight * scale * (midpointYPercent || 0.5) * OFFSET_PERCENTAGE;
    let positionY = -Math.round(midpointY) + dimensions.currentHeight / 2;
    const maxOffset = -(dimensions.originalHeight * scale - dimensions.currentHeight);
    // Respect boundaries
    positionY = Math.min(0, Math.max(positionY, maxOffset));
    return positionY;
}
export class ClassWatcher {
    constructor(targetNode, cb) {
        this.mutationCallback = mutationsList => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    this.cb();
                }
            }
        };
        this.targetNode = targetNode;
        this.cb = cb;
        this.observer = null;
        this.init();
    }
    init() {
        this.observer = new MutationObserver(this.mutationCallback);
        this.observe();
    }
    observe() {
        this.observer.observe(this.targetNode, { attributes: true });
    }
    disconnect() {
        this.observer.disconnect();
    }
}
