<script lang="ts">
  import type { Video } from "../models/Video";
  import JobTitleChip from "../components/JobTitleChip.svelte";

  export let video: Video;
</script>

<div class="person">
  <div class="toprow">
    <div class="photo">
      <img src={video.owner_data.photoURL} alt={video.owner_data.displayName} />
    </div>
    <div class="name">
      {video.owner_data.displayName}
    </div>
  </div>
  {#if video.owner_data?.title}
    <JobTitleChip jobtitle={video.owner_data?.title} />
  {/if}
</div>

<style>
  .toprow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.4em;
  }

  .photo {
    width: 2.5em;
    height: 2.5em;
    border-radius: 2em;
    overflow: hidden;
    background-color: black;
    margin-right: 0.5em;
  }

  .photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .person {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.1em;
  }
</style>
