<div class="jobtitle">{jobtitle}</div>

<script lang="ts">
  export let jobtitle: string;
</script>

<style lang="scss">
.jobtitle {
  font-size: 0.975em;
  font-weight: 400;
  display: inline-block;
  padding: 0.5em 1em;
  padding-bottom: 0.6em;
  border-radius: 100rem;
  background-color: rgba(255,255,255, 0.85);
  color: #333;
}
</style>