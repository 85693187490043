<script lang="ts">
  import { sizingState } from "../stores/sizing";
  import {
    canPlayPrevVideo,
    gotoPrevVideo,
    gotoNextVideo,
    canPlayNextVideo,
    firstPlay,
  } from "../stores/videos";
  import { pushEvent } from "../stores/analytics";
  import ArrowUp from "virtual:icons/mdi/arrow-up?raw&width=1em&height=1em";
  import ArrowDown from "virtual:icons/mdi/arrow-down?raw&width=1em&height=1em";

  function onClickNext() {
    gotoNextVideo();
    pushEvent("click_next_arrow_mobile", {});
  }

  function onClickPrev() {
    gotoPrevVideo();
    pushEvent("click_prev_arrow_mobile", {});
  }
</script>

<div class="totaloverlay">
  {#if ($sizingState === "mobile" || $sizingState === "square") && !$firstPlay}
    <div class="arrows">
      {#if $canPlayPrevVideo}
        <div class="navbutton nav-up" on:click={onClickPrev}>
          {@html ArrowUp}
        </div>
      {/if}

      {#if $canPlayNextVideo}
        <div class="navbutton nav-down" on:click={onClickNext}>
          {@html ArrowDown}
        </div>
      {/if}
    </div>
  {/if}
</div>

<style lang="scss">
  .totaloverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;
  }

  .arrows {
    position: absolute;
    bottom: 1em;
    right: 1em;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  .navbutton {
    width: 2.5em;
    height: 2.5em;
    background-color: #f1f1f1;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: all;
    color: black;
    &.nav-down {
      margin-top: 0.5em;
    }
  }
</style>
