import App from "./App.svelte";
import { initializeApp } from "firebase/app";
initializeApp({
    apiKey: "AIzaSyBB-nPJK7c7_tM67v76-vHEPr1IP0RBkDk",
    authDomain: "tmrrw-app.firebaseapp.com",
    databaseURL: "https://tmrrw-app.firebaseio.com",
    projectId: "tmrrw-app",
    storageBucket: "tmrrw-app.appspot.com",
    messagingSenderId: "951864652891",
    appId: "1:951864652891:web:58804fe722524d87377b7e",
    measurementId: "G-TYVLZVSXP8",
});
const app = new App({
    target: document.body,
});
window["__build_date"] = process.env.BUILD_DATE;
window["__build_hash"] = process.env.BUILD_HASH;
export default app;
