<div class={`info-overlay ${animateProgressBar ? 'animate-bar' : ''}`}>
    <div class="background-top"></div>
    {#if ($sizingState === 'mobile' || $sizingState === 'square')}
        <div class="background-bottom"></div>
    {/if}
    <div class="progressbar" style="transform: scaleX({$currentVideoProgress})"></div>
    {#if ($sizingState === 'mobile' || $sizingState === 'square') && video.meta?.question}
        <div class="question">
            { video.meta?.question }
        </div>
    {/if}
    <div class="person">
        <Person video="{video}" />
    </div>
</div>

<script lang="ts">
import Person from './Person.svelte';
import type { Video } from '../models/Video';
import { sizingState } from '../stores/sizing';
import { currentVideoProgress, currentVideoIndex, firstPlay } from '../stores/videos';
import { globalPaused } from '../stores/player';
export let video: Video;
let animateProgressBar = false;

// Do something when the index changes
currentVideoIndex.subscribe(() => {
    skipAnimation();
})

// Also skip animation when paused
globalPaused.subscribe(() => {
    skipAnimation();
})

function skipAnimation() {
    animateProgressBar = false;
    setTimeout(() => {
        animateProgressBar = true;
    }, 1)
}
</script>

<style lang="scss">
@import '../mixins.scss';

.background-top {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 25%);
}.background-bottom {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.7) 100%);
}
.info-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    transform: translate3d(0, 0, 0);
}

.person {
    position: absolute;
    left: 1em;
    top: 1.5em;
    :global(.state-mobile) &,
    :global(.state-square) & {
        bottom: 1.5em;
        top: auto;
    }
}

.question {
    position: absolute;
    top: 1em;
    left: 1em;
    margin-right: 1em;
    display: inline-block;
    padding: 0.5em 1em;
    padding-bottom: 0.6em;
    border-radius: 100em;
    border-top-left-radius: 0;
    overflow: hidden;
    background-color: var(--colors-primary);
    color: #FFFFFF;
}

.progressbar {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--colors-primary);
    height: 0.5em;
    width: 100%;
    transform: scaleX(0);
    :global(.animate-bar) & {
        transition: transform 0.25s linear;
    }
    will-change: transform;
    transform-origin: left;
  }
</style>