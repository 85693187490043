import { writable } from "svelte/store";
const minVideoRatio = 1080 / 1920;
const minTotalRatio = minVideoRatio / 2;
// Internal variables
let _totalW = 0;
let _playerW = 0;
let _playerH = 0;
let _baseSize = 10;
let _w = 0;
let _h = 0;
let _state = 'none';
// These variables are set by App.svelte on resizing.
export const windowWidth = writable(0);
export const windowHeight = writable(0);
// Exposed for the player
export const totalW = writable(0);
export const playerW = writable(0);
export const playerH = writable(0);
export const baseSize = writable(0);
export const sizingState = writable('none');
windowWidth.subscribe((value) => {
    _w = value;
    recalcState();
});
windowHeight.subscribe((value) => {
    _h = value;
    recalcState();
});
export function recalcState() {
    const screenRatio = _w / _h;
    if (screenRatio < minVideoRatio) {
        // The player is too narrow, we should probably use the mobile view
        _state = 'mobile';
    }
    else if (screenRatio < minVideoRatio * 2) {
        _state = 'square';
    }
    else if (screenRatio < minVideoRatio * 3) {
        _state = 'perfect';
    }
    else if (screenRatio < minVideoRatio * 4) {
        _state = 'wide';
    }
    else {
        _state = 'too-wide';
    }
    sizingState.set(_state);
    recalSize();
}
function recalSize() {
    const screenRatio = _w / _h;
    if (_state === 'mobile') {
        // _playerW = _w;
        // _playerH = _w / minVideoRatio;
        // _totalW = _playerW;
        _playerH = _h;
        _playerW = _w;
        _totalW = _playerW;
    }
    if (_state === 'square') {
        // _playerH = _h;
        // _playerW = _h * minVideoRatio;
        // _totalW = _playerW;
        _playerH = _h;
        _playerW = _w;
        _totalW = _playerW;
    }
    if (_state === 'perfect') {
        _playerH = _h;
        _playerW = _w / 2;
        _totalW = _w;
    }
    if (_state === 'wide') {
        _playerH = _h;
        _playerW = Math.min(_w / 2, _h * minVideoRatio * 1.4);
        _totalW = _w;
    }
    if (_state === 'too-wide') {
        _playerH = _h;
        _playerW = Math.min(_w / 2, _h * minVideoRatio * 1.4);
        _totalW = _w;
    }
    //   // Landscape
    //  if (screenRatio < minVideoRatio) {
    //     // Height is leading.
    //     _playerH = _h;
    //     _playerW = Math.round(_h / minVideoRatio / 2);
    //   }
    //   //Portrait
    //   else {
    //     //Width is leading
    //     _playerW = _w / 2;
    //     _playerH = Math.round(_w * minVideoRatio);
    //   }
    //   _totalW = _playerW * 3;
    _baseSize = Math.min(16, Math.max(11, Math.round(_playerW / 30)));
    // Report variables
    totalW.set(_totalW);
    playerW.set(_playerW);
    playerH.set(_playerH);
    baseSize.set(_baseSize);
}
;
