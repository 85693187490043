<script type="ts">
import TmrrwVideo from './TmrrwVideo.svelte';
import InfoOverlay from './InfoOverlay.svelte';
import type { Video } from '../models/Video';
import { parseParams } from '../models/URLParams';

export let video: Video;
export let index: number;
const urlParams = new URLSearchParams(window.location.search);
let params = parseParams(urlParams);
</script>

<div class="totalvideo">
    <div class="video"><TmrrwVideo video="{video}" index={index} /></div>
    {#if !params.fullscreen}
        <InfoOverlay video="{video}" />
    {/if}	
</div>

<style>
    .video {
        width: 100%;
        height: 100%;
    }
    .totalvideo {
        position: relative;
        width: var(--player-width);
        height: var(--player-height);
    }
</style>