<script lang="ts">
  import { currentVideo, gotoVideo } from "../stores/videos";
  import { derived } from "svelte/store";
  import { pushEvent } from "../stores/analytics";

  import Clock from "virtual:icons/mdi/clock?raw&height=1.2em";

  let video = $$props.video;
  let index = $$props.index;
  export let el = null;
  let imageEl;

  let isActive = derived(currentVideo, ($currentVideo) => {
    return $currentVideo.id === video.id;
  });

  function onItemClick(index, video_id) {
    pushEvent("click_question_item", {
      video_id,
      index,
      video_question: video.meta.question || "Vlog / No Question",
      meta: video.meta,
    });
    gotoVideo(index);
  }
</script>

{#if video.meta}
  <div
    class="question"
    class:active={$isActive}
    on:click={() => {
      onItemClick(index, video.id);
    }}
    bind:this={el}
  >
    <div class="question-info">
      <div class="thumbnail">
        <img
          src={`https://player.tmrrw.nl/thumbnail/150x150/${video.id}`}
          bind:this={imageEl}
          alt=""
        />
      </div>
      <div class="body">
        {video.meta.question || "Vlog"}
        <div class="time">
          {@html Clock}
          {Math.round(video.duration)}s
        </div>
      </div>
    </div>
  </div>
{/if}

<style lang="scss">
  .question {
    font-size: 1.125em;
    padding: 1em 1em;
    padding-bottom: 0;
    cursor: pointer;
    color: #333;

    .time {
      color: #ccc;
    }

    &:hover,
    &.active {
      .question-info {
        background-color: #f7f8fb;
      }
    }

    .question-info {
      padding: 0.75em;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 0.5em;
    }
  }

  .thumbnail {
    border-radius: 0.5em;
    width: 4em;
    height: 4em;
    margin-right: 1em;
    overflow: hidden;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
</style>
