<svg viewBox="0 0 106 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style={$$props.style}>
  <g clip-path="url(#clip0)">
  <path d="M97.999 20.4227C97.8019 20.8828 97.7031 21.3758 97.7031 21.9017H105.394C105.394 21.3758 105.295 20.8828 105.098 20.4227C104.901 19.9406 104.627 19.5243 104.276 19.1738C103.926 18.8232 103.51 18.5493 103.027 18.3521C102.567 18.1549 102.074 18.0563 101.549 18.0563C101.023 18.0563 100.519 18.1549 100.037 18.3521C99.5766 18.5493 99.1711 18.8232 98.8206 19.1738C98.4701 19.5243 98.1961 19.9406 97.999 20.4227Z" fill="currentColor"/>
  <path d="M5.93655 21.8994V10.0262H9.31482V5.07363H5.93655V0.022644H0V5.07363V10.0262V21.8994H5.93655Z" fill="currentColor"/>
  <path d="M11.4844 5.07327H17.4209V7.13959C19.039 5.58711 20.8539 4.81088 22.8655 4.81088C25.3582 4.81088 27.2605 5.86044 28.5725 7.95953C29.9063 5.83857 31.8086 4.77808 34.2795 4.77808C35.0666 4.77808 35.8101 4.89834 36.5098 5.13886C37.2095 5.35752 37.8108 5.71831 38.3137 6.22122C38.8166 6.70227 39.2102 7.33635 39.4945 8.12352C39.8006 8.91069 39.9537 9.86186 39.9537 10.977V21.899H34.0171V13.6009C34.0171 12.7481 33.9515 12.0484 33.8203 11.5018C33.711 10.9551 33.536 10.5288 33.2955 10.2226C33.0768 9.91652 32.8144 9.70879 32.5083 9.59946C32.2022 9.49014 31.8742 9.43547 31.5244 9.43547C29.622 9.43547 28.6709 10.824 28.6709 13.6009V21.899H22.7343V13.6009C22.7343 12.7481 22.6796 12.0484 22.5703 11.5018C22.461 10.9333 22.297 10.485 22.0783 10.157C21.8816 9.82906 21.6192 9.6104 21.2912 9.50107C20.9632 9.36987 20.5805 9.30428 20.1432 9.30428C19.7715 9.30428 19.4216 9.35894 19.0937 9.46827C18.7657 9.5776 18.4705 9.79626 18.2081 10.1242C17.9676 10.4304 17.7708 10.8677 17.6177 11.4362C17.4865 11.9828 17.4209 12.7044 17.4209 13.6009V21.899H11.4844V5.07327Z" fill="currentColor"/>
  <path d="M61.503 5.07325H55.5664V21.899H61.503V14.6833C61.503 13.1089 61.8419 11.9172 62.5197 11.1082C63.2195 10.2773 64.2581 9.86187 65.6356 9.86187C66.1243 9.86187 66.5944 9.9176 67.046 10.029V4.71246C65.8215 4.71246 64.7501 4.98579 63.8317 5.53243C62.9133 6.05721 62.1371 6.82252 61.503 7.82835V5.07325Z" fill="currentColor"/>
  <path d="M48.0694 5.07325H42.1328V21.899H48.0694V14.6833C48.0694 13.1089 48.4083 11.9172 49.0862 11.1082C49.7859 10.2773 50.8245 9.86187 52.202 9.86187C52.6907 9.86187 53.1608 9.9176 53.6124 10.029V4.71246C52.3879 4.71246 51.3165 4.98579 50.3981 5.53243C49.4797 6.05721 48.7035 6.82252 48.0694 7.82835V5.07325Z" fill="currentColor"/>
  <path d="M74.5472 5.07281L78.5815 13.9285L82.7797 5.07281H86.5188L90.717 14.1908L94.9481 5.07281H101.541L92.7506 21.8985H88.7819L84.5837 12.6821L80.5822 21.8985H76.548L67.9219 5.07281H74.5472Z" fill="currentColor"/>
  </g>
  <defs>
  <clipPath id="clip0">
  <rect width="105.42" height="21.9321" fill="currentColor"/>
  </clipPath>
  </defs>
</svg>
  