<script>
  import Play from "virtual:icons/mdi/play?raw&width=1em&height=1em";
</script>

<div class="playbutton">
  <div class="bg"></div>
  <div class="icon">
    {@html Play}
  </div>
</div>

<style lang="scss">
  .playbutton {
    display: block;
    position: relative;
    width: 5em;
    height: 5em;
    border-radius: 5em;
    transition: transform 0.2s;
    transform: scale3d(1, 1, 1);
    cursor: pointer;
    overflow: hidden;

    .bg {
      position: absolute;
      inset: 0;
      background-color: var(--colors-primary);
      opacity: 0.8;
    }

    &:hover {
      transform: scale3d(1.1, 1.1, 1);
      .icon {
        transform: scale3d(1.25, 1.25, 1);
      }
    }

    .icon {
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      inset: 4px;
      font-size: 1.75em;
      margin-left: 2px;
      transform: scale3d(1, 1, 1);
      transition: transform 0.2s;
      will-change: transform;
    }
  }
</style>
